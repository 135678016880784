@import "../../theme/index.module.scss";

.form_wrapper {
  background: white;
  width: 90%;
  margin: auto;
  padding: 2em 0;
  position: relative;

  @include bp($medium) {
    width: 600px;
    padding: 4em;
  }

  h1 {
    color: black;
    font-size: 23px;
    text-transform: uppercase;
    line-height: 27px;
    font-weight: 700;
    letter-spacing: .1em;
  }

  p {
    color: black;
    font-size: 15px;
    font-weight: 400;
    margin: 2em 0 0;
  }

  .SuccessMsg {
    padding: 1em 0em;
  }
}

.Overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  will-change: opacity;
  height: 100vh;
}

.CloseOverlay {
  position: absolute;
  top: 0;
  right: 2rem;
  z-index: 3;
  display: block;
  padding: 0.725rem;

  @include bp($medium) {
    top: .5rem;
    right: 2.5rem;
  }
}

.form_wrap {
  margin-bottom: 0;
  padding-bottom: 1.45rem;
  color: #fff;
  text-align: center;
  height: 100%;
  background: #e501007a;
  display: flex;
}


.form_wrapperSuccess {
  background: white;
  width: 90%;
  margin: auto;
  padding: 2em 0;

  @include bp($medium) {
    width: 600px;
    padding: 4em;
  }

  h1 {
    color: black;
    font-size: 23px;
    text-transform: uppercase;
    line-height: 27px;
    font-weight: 700;
    letter-spacing: .1em;
  }

  p {
    color: black;
    font-size: 15px;
    font-weight: 400;
    margin: 2em 0 0;
  }

  .SuccessMsg {
    padding: 1em 0 1.5em;
  }

  .CloseOverlay {
    position: relative;
    right: -100px;
    top: -30px;
  }
}


.FormFrame {
  padding: 0 1.45rem 1.45rem 1.45rem;
  border: 2px solid $white;
  border-radius: 5px;
  font-size: ms(1);
}

.error {
  font-size: ms(-1);
  text-indent: 0.725rem;
  text-align: left;
}

.Well {
  composes: well_l;
  padding: 0;

  @include bp($medium) {
    padding-bottom: 0;
  }

  button {
    color: red;
    text-transform: uppercase;
    letter-spacing: 2px;
    height: 40px;
    border: none;
    background: transparent;
    font-size: 18px;
    font-weight: 700;

    &:hover {
      border: none;
      color: red;
      background: transparent;
    }

    span {
      display: flex;
      align-items: center;
    }

    svg {
      margin-left: .5em;
    }
  }
}

.Field {
  label {
    color: black;
  }
}

.IndentField {
  composes: Field;
}




// @media screen and (min-width: 320px) {
//   .Shadow {
//       position: relative;
//     .CloseOverlay {
//       position: relative;
//       right: -120px;
//       top: -30px;
//     }
//   }

//   .form_wrapperSuccess {
//     .CloseOverlay {
//       right: -120px;
//       top: -30px;
//     }
//   }
// }

// @media screen and (min-width: 375px) {
//   .Shadow {
//       position: relative;
//     .CloseOverlay {
//       position: relative;
//       right: -130px;
//       top: -30px;
//     }
//   }

//   .form_wrapperSuccess {
//     .SuccessMsg {
//       padding: 1em 0.5em 1.5em;
//     }
//     .CloseOverlay {
//       right: -130px;
//       top: -30px;
//     }
//   }
// }


// @media screen and (min-width: 414px) {
//   .Shadow {
//       position: relative;
//     .CloseOverlay {
//       position: relative;
//       right: -150px;
//       top: -30px;
//     }
//   }

//   .form_wrapperSuccess {
//     .CloseOverlay {
//       position: relative;
//       right: -145px;
//       top: -30px;
//     }
//   }
// }

// @media screen and (min-width: 768px) {
//   .Shadow {
//     position: relative;
//     .CloseOverlay {
//       position: relative;
//       right: -250px;
//       top: -50px;
//     }
//   }
// }


// // @media screen and (min-width: 1280px) {
// //   .Shadow {
// //     .CloseOverlay {
// //       position: relative;
// //       right: -250px;
// //       top: 250px;
// //     }
// //   }
// // }
